// 
// 
// divider.scss
//
// Used for the <svg> triangle page dividers seen in some landing pages

.decorative-divider{
  display: block;
  position: relative;
  z-index: 0;
  height: 6rem;
  width: 100%;
}

section{
  > .decorative-divider:first-child{
    bottom: 1px;
  }
}

@include media-breakpoint-down(md) {
  .decorative-divider{
    height: 3rem;
  }
}

@include media-breakpoint-down(sm) {
  .decorative-divider{
    display: none;
  }
}