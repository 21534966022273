// 
// 
// blog.scss
//
//

.blog-post-author{
  .media-body{
    > *{
      display: block;
    }
    small:nth-child(1){
      color: $headings-color;
      + small{
        color: $gray-600;
      }
    }
    .h6{
      margin-bottom: 0;
    }
  }
}

.lead + .blog-post-author{
  margin-top: $spacer*2;
}
