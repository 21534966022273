// 
// 
// badge.scss
//
//

// Bootstrap Overrides
//
// General overrides for default badge styles

.badge{
  vertical-align: middle;
}

.badge-warning{
  color: $white;
}

// Additional badge styles
//
//

.badge-indicator{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  padding: 0;
  position: relative;
  bottom: 2px;
  &:empty{
    display: inline-block;
  }
}