// 
// 
// progress.scss
//
//


// Bootstrap overrides
//
// General overrides for default table styles

.progress{
  border-radius: 100rem;
}


// Additional modifiers

.progress-sm{
  height: .5rem;
}