// 
// 
// section-fullwidth-split.scss
//
// Custom component used for full-window-width sections with half image and half text content

.fullwidth-split{
  padding: 0;
  .container-fluid{
    padding-left: 0;
    padding-right: 0;
  }
}

.fullwidth-split-text{
  display: flex;
  justify-content: center;
  padding: $spacer*4 0;
}

@include media-breakpoint-down(xs) {
  .fullwidth-split-text{
    padding: $spacer*2 0;
  }
  .fullwidth-split-image{
    .bg-image{
      position: relative;
    }
  }
}
