// 
// 
// logo.scss
//
// Basic utility classes to control size of logos about the template


.logo{
  height: $spacer*1.5;
  width: auto;
  &.logo-lg{
    height: $spacer*2;
  }
  &.logo-sm{
    height: $spacer*0.75;
  }
}