// 
// 
// background-images.scss
//
//


// Markup:
//
// <section> > <img.bg-image>

section{
  position: relative;
}

.bg-image{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  + .card-body{
    position: relative;
    z-index: 1;
  }
}

img.bg-image{
  object-fit: cover;
}