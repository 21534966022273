// 
// 
// tables.scss
//
//


// Bootstrap overrides
//
// General overrides for default table styles

thead{
  tr{
    th{
      font-weight: $font-weight-normal;
      color: $headings-color;
    }
  }
}

tbody{
  tr{
    th{
      font-weight: $font-weight-normal;
    }
  }
}

.table{
  thead{
    th{
      border-bottom-width: 1px;
    }
  }
  &.align-items-center{
    th,td{
      vertical-align: middle;
    }
  }
}

.table-hover tr{
  transition: background-color .2s ease;
}

// Additional table styles
//
//

.table-borderless tr.bg-white{
  > th, > td{
    padding: 1rem;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      left: 0;
      top: 0;
      pointer-events: none;
    }
  }
  > th{
    border-radius: $border-radius 0 0 $border-radius;
    &:after{
      border-radius: $border-radius 0 0 $border-radius;
      border-left: 1px solid $border-color;
    }
  }
  > td:last-child{
    border-radius: 0 $border-radius $border-radius 0;
    &:after{
      border-radius: 0 $border-radius $border-radius 0;
      border-right: 1px solid $border-color;
    }
  }
}

.table-hover tr.bg-white:hover{
  background-color: $gray-200 !important;
}

tr.table-divider{
  height: $spacer/2;
}