// 
// 
// card.scss
//
//


// Bootstrap overrides
//
// General overrides for default card styles

.card{
  margin-bottom: $spacer;
}

.card.row{
  margin-left: 0;
  margin-right: 0;
  > [class*='col']:not(.card-body){
    padding-left: 0;
    padding-right: 0;
  }
}

@include media-breakpoint-up(md) {
  .card.row{
    > [class*='col']:not(.card-body){
      &:nth-child(2){
        &:last-child{
          border-left: 1px solid $border-color;
        }
      }
    }
  }
}

.bg-dark{
  .card{
    &:not(.bg-dark){
      color: $body-color;
      h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6,.display-1,.display-2,.display-3,.display-4,{
        color: $headings-color;
      }
    }
  }
}

.card > .bg-image{
  border-radius: $border-radius;
}

@include media-breakpoint-down(xs) {
  .card{
    margin-bottom: $spacer/2;
  }
}

.card-header{
  padding: $spacer*.75 $spacer;
  h6,.h6{
    margin: 0;
    display: inline-block;
  }
}

.card-header, .card-footer{
  background: none;
}

.card{
  > .list-group{
    > .list-group-item{
        &:not(:first-child){
          border-top: $card-border-width solid $card-border-color;
        }
      > .media:only-child{
        padding: $spacer/4 0;
      }
    }
    > a.list-group-item{
      color: $body-color;
      background: $white;
      transition: background-color .2s ease;
      &:hover{
        background-color: $gray-100;
      }
    }
    + .card-footer{
      border-top: none;
    }
  }
}

.card-header{
  + .list-group-flush{
    > .list-group-item:first-child{
      border-top: none;
    }
  }
}

h5.card-title{
  line-height: 1.5rem;
}

@include media-breakpoint-up(md) {
  .card-body[class*='col-']:nth-child(2){
    border-radius: 0 $border-radius $border-radius 0;
  }
}

.card-body.overflow-auto{
  max-height: 500px;
  max-width: 100%;
}

@include media-breakpoint-down(md) {
  .card.flex-row{
    .col-auto{
      max-width: 100%;
    }
  }
}

// Card sizes
//
// Additional classes to adjust the padding of the .card-body

.card-sm{
  .card-header, .card-footer{
    padding: $spacer/2 $spacer;
  }
  .card-body{
    padding: $spacer/2 $spacer;
  }
  .card-img-overlay{
    padding: $spacer/1.5;
  }
}

@include media-breakpoint-up(lg) {
  .card-lg{
    .card-header, .card-footer{
      padding: 1rem $spacer*1.5;
    }
    .card-body{
      padding: $spacer*1.5;
    }
  }  
}


// Card spacing
//
// Adjust spacing when cards interact with other elements

@include media-breakpoint-up(sm) {
  p + .card{
    margin-top: $spacer*1.5;
  }
}

// Additional Card modifiers
//
// Multipurpose modifiers for cards

.card-borderless{
  border: none;
}

.card-header-borderless{
  border-bottom: none;
  padding-bottom: 0 !important;
}

.card-footer-borderless{
  border-top: none;
  padding-top: 0 !important;
}

a.card{
  box-shadow: 0px 0px 0px rgba(0,0,0,0);
  transition: transform .2s ease, box-shadow .2s ease;
  &:hover{
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0,0,0,0.08);
  }
}

.card{
  iframe{
    border: none;
    outline: none;
  }
}

// Cards as tabs
//
// When cards are used as tabs in a Bootstrap 'nav' element

.tab-content:not([class*='col']){
  padding-left: 0;
}

.card[data-toggle="tab"]{
  cursor: pointer;
  transition: background-color .35s ease, border-color .35s ease;
  &:not([aria-selected="true"]){
    background-color: transparent;
    border-color: transparent; 
    &:hover{
      background-color: $gray-200;
    }
  }
}

.nav-cards{
  > li{
    width: 100%;
  }
}

// <forms> inside cards
//
// Correct colour for forms nested inside .card element

.card{
  .form-group{
    label{
      color: $gray-800 !important;
    }
    small{
      color: $body-color;
    }
  }
}

// Profile cards
//
// When cards are used as tabs in a Bootstrap 'nav' element

.card-profile-large{
  .card-header{
    position: relative;
    overflow: hidden;
    height: $spacer*7;
    *:not(.bg-image){
      position: relative;
    }
  }
  .card-body{
    margin-top: -($spacer*3);
    position: relative;
    z-index: 2;
    .avatar{
      border: 2px solid $white;
    }
  }
}