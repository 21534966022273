// 
// 
// modal.scss
//
//


// Bootstrap overrides
//
// General overrides for default modal styles

.modal.fade .modal-dialog{
  transform: none;
  transition: opacity .2s ease-out;
  opacity: 0;
}

.modal.show .modal-dialog{
  opacity: 1;
}

.modal-content{
  iframe{
    background: $black;
  }
}

// Additional Modal modifiers
//
// Multipurpose modifiers for modals

.modal-center-viewport{
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
  justify-content: center;
  pointer-events: none;
  .modal-content{
    width: 100%;
    pointer-events: all;
  }
}

.modal-header-borderless{
  border-bottom: none;
  padding-bottom: 0;
}

.modal-footer-borderless{
  border-top: none;
  padding-top: 0;
}